import React, { useState, useEffect } from "react";
import api from "../api";
import { ViewContain } from "../styled_components/layout/ViewContain";
import LoadingBars from "../styled_components/shared/LoadingBars";
import { InfoTitle } from "../styled_components/shared/InfoTitle";
import LoginField from "../styled_components/form/LoginField";
import { GreenButton } from "../styled_components/shared/GreenButton";
import styled from "styled-components";
import { InfoSubtitle } from "../styled_components/shared/InfoSubtitle";

// Change for setups
const configuration = {
  invalid_email: "invalid_email",
  password_empty: "password_empty",
  no_registered_email: "no_registered_email",
  email_success: "email_success",
  email_empty: "email_empty",

  fields: {
    email: "",
    password: "",
  },
};

const testEnvironment = process.env.REACT_APP_NODE_ENV === 'test'

const regexEmailValidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const LoginView = ({ authenticity_token, texts, fromModal }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(configuration.fields);
  const [loginForm, setLoginForm] = useState(true);

  const callError = (msg) => {
    alert(msg);
    setLoading(false);
  };

  const handleInputs = (e) => {
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    if (value === undefined || value === null) {
      value = e.target.getAttribute("value");
    }
    if (value === "true") value = true;
    if (value === "false") value = false;
    const keys = e.target.name.split(".");
    setData((prev) => {
      return { ...prev, [keys[0]]: value };
    });
  };

  const validate = () => {
    if (!regexEmailValidation.test(data.email)) { 
      callError(texts[configuration.invalid_email]); 
      return false; 
    }
    return true;
  };

  const processResponse = (res) => {
    if (!res.success || res.success === false) {
      callError(res.response);
    } else {
      if (fromModal) {
        window.location.reload()
      }else{
        const testPath = testEnvironment? "/marketplace" : ""
        window.location = testPath + ("/profile/" + res.user?.id);
      }
    }
  };

  const logIn = () => {
    const information = {
      email: data.email,
      password: data.password,
      authenticity_token,
    };
    api.login(information, processResponse);
  };

  const handleSubmit = (e) => {
    console.log('e', e)
    e.preventDefault();
    
    /* Validations */
    if (!validate(e)) return;
    /* After Validations */
    setLoading(true);
    /* API CALLS */
    logIn();
  };

  return (
    <Login>
      <LoginContainer $fromModal={fromModal}>
        <LoginSliderCont $loginView={loginForm}>
          <LoginFormView>
            <LoginForm onSubmit={handleSubmit} action="">
              <ViewContain>
                <ViewContain $alignItems={"center"}>
                  <LoginIcon src="/images2/icons/user_icon_desktop.png" alt="User"/>
                  <InfoTitle style={{margin: "0", fontSize: "4rem", fontWeight: "400", color: "var(--black2)"}} $textTransform={"uppercase"}>{texts.form_title}</InfoTitle>
                </ViewContain>
                <ViewContain $paddingY={"5%"}>
                  <LoginField name="email" type="email" label={texts["form_email"]} value={data.email} onChange={handleInputs} required/>
                  <LoginField name="password" type="password" label={texts["form_password"]} value={data.password} onChange={handleInputs} required/>
                  <ViewContain $alignItems={"center"}>
                    <LoginButtonCont>
                      {loading ?
                      <LoadingBars/>
                      :
                      <GreenButton $width={"19.7rem"} $height={"4rem"} >{texts["form_button"]}</GreenButton>}
                    </LoginButtonCont>
                  </ViewContain>
                </ViewContain>
              </ViewContain>
              <ViewContain $flexDirection={"row"} $alignItems={"center"} $justifyContent={"space-between"}>
                <LoginLink href={`${testEnvironment?"/marketplace":""}/signin`}>{texts["link_signIn"]}</LoginLink>
                <LoginLink onClick={() => setLoginForm(false)}>{texts["form_forgot"]}</LoginLink>
              </ViewContain>
            </LoginForm>
          </LoginFormView>
          <LoginFormView>
            <Recovery {...{t: texts, callError, setLoginView: setLoginForm, loading, setLoading}}/>
          </LoginFormView>
        </LoginSliderCont>
      </LoginContainer>
    </Login>
  );
};

function Recovery({ t, callError, setLoginView, loading, setLoading }) {
  const [email, setEmail] = useState("");

  const processResponse = (res) => {
    if (!res.success || res.success === false) {
      callError(t[configuration.no_registered_email]);
    } else {
      callError(t[configuration.email_success]);
      setLoginView(true);
    }
  }; 

  const recovery = (e) => {
    e.preventDefault();
    if (!regexEmailValidation.test(email)) return callError(t[configuration.invalid_email])
    setLoading(true)
    const information = {
      email: email,
    };
    api.forgotPass(information, processResponse);
  };

  return (
    <ResetPasswordForm onSubmit={recovery}>
      <ViewContain $alignItems={"center"}>
        <InfoSubtitle style={{fontFamily: "var(--BarlowCondensed)", fontWeight: "400", color: "var(--black2)"}}>{t["recovery_message"]}</InfoSubtitle>
      </ViewContain>
      <LoginField name="email" type="email" label={t["recovery_email"]} value={email} onChange={(e) => setEmail(e.target.value)} required/>
      <ViewContain $alignItems={"center"} $paddingY={"2%"}>
        <LoginButtonCont>
          {loading ?
          <LoadingBars/>
          :
          <GreenButton type="submit" $width={"19.7rem"} $height={"4rem"} $textTransform={"uppercase"}>{t["recovery_button"]}</GreenButton>}
        </LoginButtonCont>
      </ViewContain>
      <ViewContain $alignItems={"center"} $paddingY={"2%"}>
        <LoginLink onClick={() => setLoginView(true)}>{t["recovery_button_back"]}</LoginLink>
      </ViewContain>
    </ResetPasswordForm>
  );
}

const Login = styled.div`
  height: 67rem;
  position: relative;
`

const LoginContainer = styled.div`
  position: absolute;
  top: 0; 
  left: 50%;
  width: ${({$fromModal}) => $fromModal ? "100%" : "100vw"};
  height: 100%;
  overflow: hidden;
  transform: translate(-50%, 0);
  padding: 5% 0;
  background-color: #D9D9D9;
`
const LoginSliderCont = styled.div`
  position: relative;
  display: flex;
  transition: transform 1s ease-in-out;
  transform: translateX(${({$loginView}) => $loginView ? "0" : "-100"}%);
  height: 100%;
`
const LoginFormView = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
`
const LoginForm = styled.form`
  max-width: 500px;
  width: 100%;
  margin: auto 10%;
`
const LoginIcon = styled.img`
  width: 6rem;
`
const LoginLink = styled.a`
  font-family: var(--BarlowCondensed);
  font-size: 2rem;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
`
const LoginButtonCont = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
`
const ResetPasswordForm = styled.form`
  max-width: 1000px;
  width: 100%;
  margin: auto 10%;
`

export default LoginView;