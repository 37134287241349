import React from "react"
import { ViewContain } from "../styled_components/layout/ViewContain.js"
import InfoGreyCard from "../styled_components/shared/InfoGreyCard.js"

//TO CHANGE
export default ({ data, c, form, selectedForm, saveForm, changeForm, proposals }) => (

  <div id="OpportunityLayout">
    <ViewContain $gap={"5rem"} $paddingY={"5rem"}>
      {data.map((item, i) =>
				<InfoGreyCard {...{i, c, item, form, selectedForm, saveForm, changeForm}} key={"card"+i}>
          {item.component}
        </InfoGreyCard>
			)}
    </ViewContain>
    {proposals && proposals}
  </div>
)