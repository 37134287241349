import React from 'react'
import styled from 'styled-components';
import DownIcon from '../../shared/icons/DownIcon';

const LoginField = ({ type, name, value, onChange, label, required = false, autoComplete, minLength, initialOptions, options, include_blank }) => {
  const generic = ["text", "number", "email", "password", "phone"];
  return (
    <FormGroup>
      <Label htmlFor={name}>{label}</Label>
      {generic.includes(type) && <GenericField type={type} name={name} defaultValue={value} onChange={onChange} required={required} autoComplete={autoComplete} minLength={minLength}/>}
      {type === "select" && <SelectField {...{ name, value, onChange, required, initialOptions, options, include_blank }} />}
      {type === "textarea" && <Textarea {...{ name, value, onChange, required}} />}
    </FormGroup>
  )
}

//Send options for direct [{value: "value", option: "option"}] array
const SelectField = ({ name, value, onChange, required, initialOptions = [], options = [], include_blank }) => {
  return (
    <SelectContainer>
      <DownIcon color={"#D9D9D9"}/>
      <Select defaultValue={value} {...{name, onChange, required}}>
        {include_blank && <option value=""></option>}
        {initialOptions.length > 0 && initialOptions.map((e) => (
          <option key={name + "_" + e.value} value={e.value}>
            {e.option}
          </option>
        ))}
        {initialOptions.length > 0 && 
          <option value="" disabled="disabled">
            ────────────────────
          </option>}
        {options.map((e) => (
          <option key={name + "_" + e.value} value={e.value}>
            {e.option}
          </option>
        ))}
      </Select>
    </SelectContainer>
  );
};

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: 400;
  font-family: var(--BarlowCondensed);
  color: var(--black2);
  gap: 2rem;
  align-items: flex-start;
`
const Label = styled.label`
  display: block;
  text-transform: uppercase;
`
const GenericField = styled.input`
  width: 100%;
  border: none;
  height: 3.5rem;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  padding: 0 1rem;
  background-color: white !important;
`
//select field
const SelectContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: white;
  height: 3.5rem;

  svg{
    position: absolute;
    top: 40%;
    right: 1rem;
    z-index: 0;
  }
`
const Select = styled.select`
  position: absolute;
  left: 0;
  text-transform: none;
  appearance: none;
  background-color: #ffffff00;
  width: 100%;
  height: 100%;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  padding: 0 1rem;
  border: none;
  color: var(--grey2);
`
//Textarea
const Textarea = styled.textarea`
  width: 100%;
  height: 12rem;
  padding: .5rem 3rem;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  border: none;
  resize: none;
  background-color: white !important;
`

export default LoginField