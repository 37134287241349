import React from "react";
import { LangContext } from "../Language";
import useController from "../hooks/useController";
import View from "./View";
import Form from "./Form";

export default function Controller({ opportunity, ods, identify, lang, fromShowComponent, currentUser, currentUserOrg, adminUser, removeBackEvent = ()=>{}, loginTexts = {}}) {
  let props = useController(lang, opportunity, "opportunities", fromShowComponent ? "view" : "form");
  props.currentUser = currentUser;
  props.currentUserOrg = currentUserOrg;
  props.adminUser = adminUser;
  props.loginTexts = loginTexts;

  return <LangContext.Provider value={lang}>{props.loading || (props.view === "view" ? <View {...props} hiddenPanelButtons={fromShowComponent} {...{removeBackEvent}}/> : <Form {...props} ods={ods} identify={identify} {...{removeBackEvent}} />)}</LangContext.Provider>;
}
