import React, { useState } from "react";
import { CardContain, InfoContain, ViewContain } from "../styled_components/layout/ViewContain";
import { YellowButton } from "../styled_components/shared/YellowButton";
import { CardLabel, CardRadio, NewOpportunityCard } from "../styled_components/marketplace/NewOpportunityCard";
import LoadingBars from "../styled_components/shared/LoadingBars";
import Modal from "../shared/Modal";
import LoginView from "../Login/Login";

const New = ({ texts, authenticity_token, currentUser }) => {
  const [disable, setDisable] = useState(true);
  const [data, setData] = useState({ offer: "none", otype: "none" });
  const [loading, setLoading] = useState(false)
  const [showLogin, setShowLogin] = useState(false)

  const testEnvironment = process.env.REACT_APP_NODE_ENV === 'test'

  const navigate = (url) => {
    window.location.replace(url);
  };

  const save = async () => {
    if (!currentUser) return setShowLogin(true)
    setLoading(true)
    try {
      data["authenticity_token"] = authenticity_token
      let res = await fetch(`${testEnvironment?"/marketplace":""}/opportunities`, {
        headers: {
          "Content-Type": "Application/json",
          "Accept": "Application/json",
        }, body: JSON.stringify(data), method: "POST"
      })
      if (res.status === 401) {
        setShowLogin(true)
      }
      if (res.status === 422) {
        navigate(`${testEnvironment?"/marketplace":""}/profile/${currentUser?.id}`)
      }
      const r = await res.json()
      if (r.success) {
        navigate(`${testEnvironment?"/marketplace":""}/opportunities/${r.opportunity.id}/edit`)
      }
    } catch (error) {
      setLoading(false)
      console.error("error", error)
    }
  }

  const handleData = (e, tag) => {
    const value = e.target.value
    setData({offer: tag, otype: value})
    if (disable) setDisable(false)
  }

  return (
    <>
      <ViewContain $alignItems={"center"} $paddingX={"5%"} $paddingY={"5%"}>
        <InfoContain $margin={"0 5% 5%"} >
          <h2>{texts.marketplace_new_opportunity_title}</h2>
          <span>{texts.marketplace_new_opportunity_subtitle}</span>
        </InfoContain>
        <ViewContain $alignItems={"center"}>
          <CardContain>
            <NewOpportunityCard>
              <h2>{texts.marketplace_new_opportunity_find_card}</h2>
              <span>{texts.marketplace_new_opportunity_find_card_description}</span>
              <CardLabel><CardRadio name="type" value={"cadena"} onChange={(e)=>{handleData(e, "need")}}/><span>{texts.marketplace_new_opportunity_card_option_1}</span></CardLabel>
              <CardLabel><CardRadio name="type" value={"consumo"} onChange={(e)=>{handleData(e, "need")}}/><span>{texts.marketplace_new_opportunity_card_option_2}</span></CardLabel>
            </NewOpportunityCard>
            <NewOpportunityCard $bgColor={"#361A3C"}>
              <h2>{texts.marketplace_new_opportunity_offer_card}</h2>
              <span>{texts.marketplace_new_opportunity_offer_card_description}</span>
              <CardLabel><CardRadio name="type" value={"cadena"} onChange={(e)=>{handleData(e, "offer")}}/><span>{texts.marketplace_new_opportunity_card_option_1}</span></CardLabel>
              <CardLabel><CardRadio name="type" value={"consumo"} onChange={(e)=>{handleData(e, "offer")}}/><span>{texts.marketplace_new_opportunity_card_option_2}</span></CardLabel>
            </NewOpportunityCard>
          </CardContain>
        </ViewContain>
        <ViewContain $paddingY={"5%"} $paddingX={"5%"} $alignItems={"center"}>
          {loading ?
            <LoadingBars/>
          :
            <YellowButton onClick={disable?()=>{}:save} $disable={disable} $textTransform={"uppercase"}>{texts.marketplace_new_opportunity_save_button}</YellowButton>
          }
        </ViewContain>
      </ViewContain>
      {showLogin && 
        <Modal width={"916px"} >
          <LoginView {...{authenticity_token}} texts={texts} fromModal/>
        </Modal>
      }
    </>
  );
};

export default New